import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GiSunglasses, GiCementShoes, GiHeartWings } from "react-icons/gi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
			Главная | Академия кикбоксинга Health Freaks
			</title>
			<meta name={"description"} content={"Добро пожаловать в академию Kickboxing Academy, где дисциплина встречает силу в динамичном мире кикбоксинга."} />
			<meta property={"og:title"} content={"Главная | Академия кикбоксинга Health Freaks"} />
			<meta property={"og:description"} content={"Добро пожаловать в академию Kickboxing Academy, где дисциплина встречает силу в динамичном мире кикбоксинга."} />
			<meta property={"og:image"} content={"https://solivareber.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://solivareber.com/img/boxing.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solivareber.com/img/boxing.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solivareber.com/img/boxing.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solivareber.com/img/boxing.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solivareber.com/img/boxing.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solivareber.com/img/boxing.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 120px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Академия кикбоксинга Health Freaks
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
						Добро пожаловать в академию Kickboxing Academy, где дисциплина встречает силу в динамичном мире кикбоксинга. Наша академия посвящена обеспечению высококачественной подготовки для тех, кто увлечен мастерством искусства кикбоксинга в сложной, но поддерживающей среде.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="#3f24d8"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="#3f24d8"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Связаться с нами
						</Button>
					</Box>
				</Box>
				<Image
					src="https://solivareber.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section
			padding="88px 0 88px 0"
			sm-padding="60px 0 60px 0"
			box-shadow="--m"
			background="--color-light"
			md-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-25"
		>
			<Override slot="SectionContent" max-width="1220px" sm-min-width="280px" />
			<Box
				width="100%"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				margin="0px 0px 64px 0px"
				display="flex"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 28px/1.2 --fontFamily-sans"
					text-align="center"
					md-font="--headline3"
				>
					Почему урод здоровья?
				</Text>
				<Text
					color="--darkL2"
					text-align="center"
					lg-width="100%"
					margin="0px 0px 0px 0px"
					font="--base"
					width="70%"
					lg-max-width="720px"
				>
					От начинающих до продвинутых бойцов наши программы обслуживают все уровни, сосредотачиваясь на технике, фитнесе и стратегических боевых навыках.
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px"
				lg-grid-gap="16px"
				md-grid-template-columns="1fr"
			>
				<Box
					display="flex"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					flex-direction="column"
					justify-content="flex-start"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						margin="0px 0px 30px 0px"
						color="#3f24d8"
						size="64px"
						category="gi"
						icon={GiSunglasses}
						lg-font="48px sans-serif"
					/>
					<Text font="--lead" margin="0px 0px 18px 0px" color="--darkL2" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase" text-align="center">
							Экспертное обучение
						</Strong>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Получите пользу от высшего уровня от опытных специалистов по кикбоксингу, которые стремятся помочь вам в полной мере достичь вашего потенциала.
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						icon={GiCementShoes}
						margin="0px 0px 30px 0px"
						color="#3f24d8"
						size="64px"
						category="gi"
						lg-font="48px sans-serif"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase" text-align="center">
							Младенец фокус
						</Strong>
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						text-align="center"
					>
						Наши учебные программы специально адаптированы для обеспечения строгого и полезного опыта, в первую очередь, сосредоточенной на мужской перспективе в боевых искусствах.
					</Text>
				</Box>
				<Box
					display="flex"
					background="#FFFFFF"
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						icon={GiHeartWings}
						margin="0px 0px 30px 0px"
						color="#3f24d8"
						size="64px"
						category="gi"
						lg-font="48px sans-serif"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--lead" text-align="center">
						<Strong letter-spacing="0.5px" text-transform="uppercase">
							Современные объекты
						</Strong>
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Поезд на полностью оборудованном объекте, в комплекте с кикбоксинговыми кольцами профессионального класса, переноскими мешками и фитнес-оборудованием.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Уникальные преимущества
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Персонализированные режимы обучения: получайте персонализированное внимание и адаптированные тренировки, которые обслуживают ваш индивидуальный уровень квалификации и фитнес -цели.
					<br />
					Сообщество и Братство: присоединяйтесь к сообществу единомышленников, которые разделяют вашу страсть к кикбоксингу и личному росту.
					<br />
					<br />
					<br />
					Академия Kickboxing Health Freaks - где каждый удар считается величием
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://solivareber.com/img/2.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});